<template>
    <modal ref="modalEditOrder" titulo="Editar pedido" adicional="Guardar" @adicional="editOrder">
        <ValidationObserver ref="validator">
            <div class="row mx-0 my-3 justify-content-center f-14">
                <div class="col-12 my-2">
                    <p class="input-label-top">Quien solicita</p>
                    <ValidationProvider name="solicitante" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_user_solicita" disabled filterable placeholder="Seleccionar solicitante" size="small" class="w-100">
                            <el-option v-for="item in users" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Proyecto</p>
                    <ValidationProvider name="proyecto" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_proyecto" filterable placeholder="Seleccionar proyecto" @change="getEquipos" size="small" class="w-100">
                            <el-option v-for="item in proyectos" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Equipo</p>
                    <ValidationProvider name="equipo" v-slot="{ errors }" rules="required">
                        <el-select v-model="equipo" filterable placeholder="Seleccionar equipo" size="small" class="w-100">
                            <el-option v-for="(item, idx) in productos" :key="idx" :label="item.nombre" :value="idx" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Fecha de solicitud</p>
                    <el-date-picker v-model="obj.fecha_solicitud" disabled type="date" placeholder="Seleccionar fecha" size="small" class="w-100" />
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Fecha de llegada</p>
                    <ValidationProvider name="fecha llegada" v-slot="{ errors }" rules="required">
                        <el-date-picker v-model="obj.fecha_llegada" type="date" placeholder="Seleccionar fecha" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            equipo: null,
            obj: {
                id: '',
                id_user_solicita: '',
                id_proyecto: '',
                id_equipo: '',
                id_equipo_modelo: '',
                fecha_solicitud: '',
                fecha_llegada: '',
            }
        }
    },computed: {
        ...mapGetters({
            proyectos: 'selects/selects/select_proyecto_pedido',
            user: 'auth/user',
            productos: 'selects/selects/select_producto_pedido',
            users: 'selects/selects/select_user_solicita'
        })
    },
    methods: {
        ...mapActions({
            Action_get_select_producto_pedido: 'selects/selects/Action_get_select_producto_pedido',
            Action_get_select_proyecto_pedido: 'selects/selects/Action_get_select_proyecto_pedido',
            Action_get_select_user_solicita: 'selects/selects/Action_get_select_user_solicita',
            Action_update_pedido_interno: 'almacen/pedidos/Action_update_pedido_interno'
        }),
        async toggle(data){
            await Promise.all([
                this.Action_get_select_proyecto_pedido(),
                this.Action_get_select_user_solicita()
            ])
            await this.Action_get_select_producto_pedido({id : data.id_proyecto})
            this.$refs.validator.reset()
            this.obj = {
                id: data.id,
                id_user_solicita: this.user.id,
                id_proyecto: data.id_proyecto,
                id_equipo: data.id_equipo,
                id_equipo_modelo: data.id_equipo_modelo,
                fecha_solicitud: data.fecha_solicitud,
                fecha_llegada: data.fecha_llegada,
            }
            if(data.id_equipo != null){
                this.equipo = this.productos.findIndex(item => item.id == data.id_equipo && item.tabla == 'ops_productos')
            }
            if(data.id_equipo_modelo != null){
                this.equipo = this.productos.findIndex(item => item.id == data.id_equipo_modelo && item.tabla == 'oportunidades_equipos_modelos')
            }
            this.$refs.modalEditOrder.toggle()
        },
        async getEquipos(){
            await this.Action_get_select_producto_pedido({id: this.obj.id_proyecto})
            this.equipo = ''
        },
        async editOrder(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return
            const producto = this.productos[this.equipo]
            if (producto.tabla == "oportunidades_equipos_modelos") {
                this.obj.id_equipo_modelo = producto.id
                this.obj.id_equipo = null
            }
            if (producto.tabla == "ops_productos") {
                this.obj.id_equipo_modelo = null
                this.obj.id_equipo = producto.id
            }
            await this.Action_update_pedido_interno(this.obj)
            this.$emit('update')
            this.$refs.modalEditOrder.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
