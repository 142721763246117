var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCreateOrder",attrs:{"titulo":"Crear pedido","adicional":"Crear"},on:{"adicional":_vm.createOrder}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row mx-0 my-3 justify-content-center f-14"},[_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Quien solicita")]),_c('ValidationProvider',{attrs:{"name":"solicitante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"disabled":"","filterable":"","placeholder":"Seleccionar solicitante","size":"small"},model:{value:(_vm.obj.id_user_solicita),callback:function ($$v) {_vm.$set(_vm.obj, "id_user_solicita", $$v)},expression:"obj.id_user_solicita"}},_vm._l((_vm.users),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Proyecto")]),_c('ValidationProvider',{attrs:{"name":"proyecto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar proyecto","size":"small"},on:{"change":_vm.getEquipos},model:{value:(_vm.obj.id_proyecto),callback:function ($$v) {_vm.$set(_vm.obj, "id_proyecto", $$v)},expression:"obj.id_proyecto"}},_vm._l((_vm.proyectos),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Equipo")]),_c('ValidationProvider',{attrs:{"name":"equipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar equipo","size":"small"},model:{value:(_vm.equipo),callback:function ($$v) {_vm.equipo=$$v},expression:"equipo"}},_vm._l((_vm.productos),function(item,idx){return _c('el-option',{key:idx,attrs:{"label":item.nombre,"value":[item.id, item.tabla]}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Fecha de solicitud")]),_c('ValidationProvider',{attrs:{"name":"fecha solicitud","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","placeholder":"Seleccionar fecha","size":"small"},model:{value:(_vm.obj.fecha_solicitud),callback:function ($$v) {_vm.$set(_vm.obj, "fecha_solicitud", $$v)},expression:"obj.fecha_solicitud"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Fecha de llegada")]),_c('ValidationProvider',{attrs:{"name":"fecha llegada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","placeholder":"Seleccionar fecha","size":"small"},model:{value:(_vm.obj.fecha_llegada),callback:function ($$v) {_vm.$set(_vm.obj, "fecha_llegada", $$v)},expression:"obj.fecha_llegada"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }