<template>
    <modal ref="modalCreateOrder" titulo="Crear pedido" adicional="Crear" @adicional="createOrder">
        <ValidationObserver ref="validator">
            <div class="row mx-0 my-3 justify-content-center f-14">
                <div class="col-12 my-2">
                    <p class="input-label-top">Quien solicita</p>
                    <ValidationProvider name="solicitante" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_user_solicita" disabled filterable placeholder="Seleccionar solicitante" size="small" class="w-100">
                            <el-option v-for="item in users" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Proyecto</p>
                    <ValidationProvider name="proyecto" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_proyecto" filterable placeholder="Seleccionar proyecto" @change="getEquipos" size="small" class="w-100">
                            <el-option v-for="item in proyectos" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Equipo</p>
                    <ValidationProvider name="equipo" v-slot="{ errors }" rules="required">
                        <el-select v-model="equipo" filterable placeholder="Seleccionar equipo" size="small" class="w-100">
                            <el-option v-for="(item,idx) in productos" :key="idx" :label="item.nombre" :value="[item.id, item.tabla]" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Fecha de solicitud</p>
                    <ValidationProvider name="fecha solicitud" v-slot="{ errors }" rules="required">
                        <el-date-picker v-model="obj.fecha_solicitud" type="date" placeholder="Seleccionar fecha" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Fecha de llegada</p>
                    <ValidationProvider name="fecha llegada" v-slot="{ errors }" rules="required">
                        <el-date-picker v-model="obj.fecha_llegada" type="date" placeholder="Seleccionar fecha" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            equipo: null,
            obj: {
                id_user_solicita: '',
                id_proyecto: '',
                id_equipo_modelo: null,
                id_equipo: null,
                fecha_solicitud: '',
                fecha_llegada: '',
            }
        }
    },
    computed: {
        ...mapGetters({
            proyectos: 'selects/selects/select_proyecto_pedido',
            user: 'auth/user',
            productos: 'selects/selects/select_producto_pedido',
            users: 'selects/selects/select_user_solicita'
        })
    },
    mounted() {
        this.obj.id_user_solicita = this.user.id
    },
    methods: {
        ...mapActions({
            Action_get_select_producto_pedido: 'selects/selects/Action_get_select_producto_pedido',
            Action_get_select_proyecto_pedido: 'selects/selects/Action_get_select_proyecto_pedido',
            Action_get_select_user_solicita: 'selects/selects/Action_get_select_user_solicita',
            Action_crear_pedido_interno: 'almacen/pedidos/Action_crear_pedido_interno'
        }),
        toggle(){
            Promise.all([
                this.Action_get_select_proyecto_pedido(),
                this.Action_get_select_user_solicita()
            ])
            this.$refs.modalCreateOrder.toggle()
        },
        async getEquipos(){
            await this.Action_get_select_producto_pedido({id: this.obj.id_proyecto})
        },
        async createOrder(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return
            if (this.equipo[1] == "oportunidades_equipos_modelos") {
                this.obj.id_equipo_modelo = this.equipo[0]
                this.obj.id_equipo = null
            }
            if (this.equipo[1] == "ops_productos") {
                this.obj.id_equipo_modelo = null
                this.obj.id_equipo = this.equipo[0]
            }
            await this.Action_crear_pedido_interno(this.obj)
            this.clear()
            this.$refs.modalCreateOrder.toggle()
        },
        clear(){
            this.equipo = null
            this.obj = {
                id_user_solicita: this.user.id,
                id_proyecto: '',
                id_equipo_modelo: null,
                id_equipo: null,
                fecha_solicitud: '',
                fecha_llegada: '',
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
