<template>
    <section class="pedidos-internos-listado-estado">
        <titulo-divisor titulo="Lista de pedidos">
            <div class="row">
                <div class="col auto my-auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
                <div class="col-auto my-auto px-1">
                    <el-tooltip content="Crear pedido" visible-arrow effect="light">
                        <i class="icon-plus-circle  f-30 cr-pointer"
                            :class="permitAction('almacen.pedido.agregar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.pedido.agregar', createOrder)"
                        />
                    </el-tooltip>
                </div>
                <div class="col-auto my-auto pl-1 icon-option">
                    <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="handleClearFilter"/>
                </div>
            </div>
        </titulo-divisor>
        <!-- tabla -->
        <tabla-general 
            :usar-servidor="false" 
            :usar-paginacion="false"
            :mostrarBuscador="false" 
            altura-tabla="calc(100vh - 390px)"
            row-class-name="cr-pointer"
            :data="pedido"
            @redirect="goToOrder"
        >
            <el-table-column type="index" label="#" width="100" align="center" />
            <el-table-column label="Fecha solicitud" prop="fecha_solicitud" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.fecha_solicitud | helper-fecha('DD MMM. YYYY') }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha llegada" prop="fecha_llegada">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.fecha_llegada | helper-fecha('DD MMM. YYYY') }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Solicitante" prop="solicitante" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600"> {{ scope.row.solicita }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.proyecto }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Equipo" prop="equipo">
                <template slot-scope="scope">
                    <p class="f-12 text-muted"> {{ scope.row.equipo }} </p>
                </template>
            </el-table-column>
            <el-table-column width="100" align="center">
                <template slot-scope="scope">
                    <el-tooltip content="Editar" effect="light" visible-arrow>
                        <i class="icon-pencil icon-general-hover f-18"
                            :class="permitAction('almacen.pedido.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('almacen.pedido.editar', editarPedido, [scope.row])"
                        />
                    </el-tooltip>
                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                        <i class="icon-trash-can icon-general-hover f-18" 
                            :class="permitAction('almacen.pedido.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('almacen.pedido.eliminar', deleteOrder, [scope.row.id])"
                        />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="Action_get_pedido_interno()"/>
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Solicitante</p>
                    <el-select v-model="filter.id_solicitante" clearable filterable placeholder="Seleccionar solicitante" size="small" class="w-100">
                        <el-option v-for="item in filtro_pedido_interno.solicitantes" :key="item.id_user_solicita" :label="item.nombre" :value="item.id_user_solicita" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filter.id_proyecto" clearable filterable placeholder="Seleccionar proyecto" size="small" class="w-100">
                        <el-option v-for="item in filtro_pedido_interno.proyectos" :key="item.id_proyecto" :label="item.nombre" :value="item.id_proyecto" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Equipo</p>
                    <el-select v-model="equipos" clearable filterable placeholder="Seleccionar equipo" @change="filterEquipo" size="small" class="w-100">
                        <el-option v-for="item in filtro_pedido_interno.equipos" :key="item.id_equipo" :label="item.equipo" :value="[item.id_equipo, item.modelo]" />
                    </el-select>
                </div>
                <p class="col-12 input-label-top mb-2">Rango fecha de solicitud</p>
                <div class="col-12 col-lg-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="filter.fecha_solicitud_desde" type="date" placeholder="Fecha inicio" class="w-100" size="small" />
                </div>
                <div class="col-12 col-lg-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="filter.fecha_solicitud_hasta" type="date" placeholder="Fecha fin" class="w-100" size="small" />
                </div>
                <p class="col-12 input-label-top mb-2">Rango fecha de llegada</p>
                <div class="col-12 col-lg-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="filter.fecha_llegada_desde" type="date" placeholder="Fecha inicio" class="w-100" size="small" />
                </div>
                <div class="col-12 col-lg-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="filter.fecha_llegada_hasta" type="date" placeholder="Fecha fin" class="w-100" size="small" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleClearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarPedidos"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-create-order ref="openModalCreateOrder" />
        <modal-edit-order ref="openModalEditOrder" />
        <modal-eliminar ref="openModalDeleteOrder" title="Eliminar pedido" mensaje="¿Está seguro que quiere eliminar este pedido?" @delete="handleDeleteOrder" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import modalCreateOrder from './partials/modalCreateOrder'
import modalEditOrder from './partials/modalEditOrder'
export default {
    name: 'estadoPedido',
    components: {
        modalCreateOrder,
        modalEditOrder,
    },
    data() {
        return {
            equipos: null,
            idToDelete: null,
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            pedido: 'almacen/pedidos/pedido_interno',
            filtro_pedido_interno: 'almacen/pedidos/filtro_pedido_interno'
        }),
        filter: {
            get(){
                return this.$store.getters['almacen/pedidos/filter']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_filter', val)
            }
        },
        pagination: {
            get(){
                return this.$store.getters['almacen/pedidos/pagination']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_pagination', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/pedidos/query']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_query', val)
            }
        },
        filterCount(){
            return this.filter.count();
        },
    },
    async created(){
        await this.clearFilter()
        this.Action_get_pedido_interno()
        this.getBreadcumbs([ 'almacen.main', 'almacen.pedidos.internos', 'almacen.pedidos.internos.estado.pedido' ])
    },
    methods: {
        ...mapActions({
            Action_get_select_proyecto_pedido: 'selects/selects/Action_get_select_proyecto_pedido',
            Action_get_select_user_solicita: 'selects/selects/Action_get_select_user_solicita',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_pedido_interno: 'almacen/pedidos/Action_get_pedido_interno',
            Action_delete_pedido_interno: 'almacen/pedidos/Action_delete_pedido_interno',
            Action_get_pedido_interno_filtro: 'almacen/pedidos/Action_get_pedido_interno_filtro',
            clearFilter: 'almacen/pedidos/Action_clear_filter',
        }),
        filterEquipo(){
            if(this.equipos[1] === 0){
                this.filter.id_equipo = this.equipos[0]
                this.filter.id_equipo_modelo = null
            }
            if(this.equipos[1] === 1){
                this.filter.id_equipo_modelo = this.equipos[0]
                this.filter.id_equipo = null
            }
        },
        filtro(){
            this.Action_get_pedido_interno_filtro()
        },
        beforeSearch(){
            this.delay(this.Action_get_pedido_interno)
        },
        delay(callback, tiempo=500){
			if(typeof(callback) == 'function') {
				if(window['tiempo_gbp'] == undefined){
					window['tiempo_gbp'] = 0;
				}
				if (tiempo_gbp) {
					clearInterval(tiempo_gbp)
				}
				tiempo_gbp = setTimeout(()=>{
					callback();
				}, tiempo)
			} else {
				console.warn('El primer parámetro debe ser una función.');
			}
		},
        createOrder() {
            this.$refs.openModalCreateOrder.toggle()
        },
        editarPedido(data) {
            this.$refs.openModalEditOrder.toggle(data)
        },
        deleteOrder(id) {
            this.idToDelete = id
            this.$refs.openModalDeleteOrder.toggle()
        },
        goToOrder({ id }) {
            this.$router.push({ name: 'almacen.pedidos.ver', params: { id_pedido: id } })
        },
        abrirModalFiltros() {
            this.filtro()
            this.$refs.abrirModalFiltros.toggle()
        },
        handleClearFilter(){
            this.clearFilter()
            this.equipos = null
            this.Action_get_pedido_interno()
        },
        async filtrarPedidos(){
            await this.Action_get_pedido_interno()
            this.$refs.abrirModalFiltros.toggle()
        },
        async handleDeleteOrder() {
            await this.Action_delete_pedido_interno(this.idToDelete)
            this.Action_get_pedido_interno()
        }
    },

}
</script>

<style>

</style>